import { twMerge } from 'tailwind-merge'
import Icon from './icon'

export default function ModalCloseButton({
    color = null,
    children = null,
    className = null,
    ...props
}) {
    return (
        <button
            type="button"
            className={twMerge('absolute py-6 px-4 top-0 right-0', className)}
            {...props}
        >
            {children}
            <Icon name="cross" size="smaller" color={color} />
        </button>
    )
}
