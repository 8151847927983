'use client'

import { usePathname, useRouter, useSearchParams } from 'next/navigation'

export function useUpdateSearchParams() {
    const searchParams = useSearchParams()
    const pathname = usePathname()
    const { replace } = useRouter()

    return {
        searchParams,
        removeSearchParams: (keysToDelete, options) => {
            const params = new URLSearchParams(searchParams)
            keysToDelete.forEach((key) => {
                params.delete(key)
            })
            replace(`${pathname}?${params.toString()}`, options)
        },
        addSearchParams: (valuesToSet, options) => {
            const params = new URLSearchParams(searchParams)
            Object.entries(valuesToSet).forEach(([key, value]) => {
                params.set(key, value)
            })
            replace(`${pathname}?${params.toString()}`, options)
        },
        updateSearchParams: (valuesToSet, keysToDelete, options) => {
            const params = new URLSearchParams(searchParams)
            Object.entries(valuesToSet).forEach(([key, value]) => {
                params.set(key, value)
            })
            keysToDelete?.forEach((key) => {
                params.delete(key)
            })
            replace(`${pathname}?${params.toString()}`, options)
        },
    }
}
